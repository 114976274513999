import {
    Autosuggest,
    AutosuggestProps,
    Button,
    Container,
    Form,
    FormField,
    Header,
    Input,
    Select,
    SelectProps,
    SpaceBetween,
    StatusIndicator,
    TokenGroup,
} from '@amzn/awsui-components-react-v3';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CancelModal, {
    CancelModalProps,
} from '../../../../../../common/components/CancelModal/CancelModal';
import { LocationItemData } from '../../../../../../common/interfaces/businessDataItem/locationItem';
import { InstructorProfileData } from '../../../../../../common/interfaces/instructorProfile';
import {
    getSelectedOptionValue,
    valuesToOptions,
} from '../../../../../../common/utils/cloudscapeHelpers';
import instructorManagementApi from '../../../../../../imt/api/instructorManagementApi';
import { getCityString } from '../../../../../../imt/components/Instructor/FormSections/BasicInfoFormSection';
import scheduleManagementApi from '../../../../../api/scheduleManagementApi';
import {
    activityGroupSlice,
    addDeliveryOptionWithUser,
} from '../../../../../store/slices/activityGroupSlice';
import {
    useActivityDetailsFormOptions,
    useActivityGroup,
    useDeliveryDetailsFormOptions,
} from '../../hooks/hooks';
import {
    AciMessage,
    AciResouceConstrain,
    updateValidations,
} from '../../utils/validations';
import ActivityGroupDelivery from '../ActivityGroupDelivery/ActivityGroupDelivery';

const DEFAULT_DELIVERY_NAME = 'Live Learning';
const MAX_INSTRUCTOR_SEARCH_RESULTS =
    AciResouceConstrain.MAX_INSTRUCTOR_SEARCH_RESULTS;
const MAX_INSTRUCTOR_POOL_SIZE =
    AciResouceConstrain.MAX_LAG_INSTRUCTOR_POOL_SIZE;

const ActivityGroupEditDeliveryForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const activityGroup = useActivityGroup();
    const { activityTypes, activityAudiences, activityModalities, locations } =
        useDeliveryDetailsFormOptions();
    const [instructorOptions, setInstructorOptions] =
        useState<AutosuggestProps.Options>([]);
    const [instructorSearchText, setInstructorSearchText] =
        useState<string>('');
    const [isSearchingInstructors, setIsSearchingInstructors] =
        useState<boolean>(false);
    const [newDeliveryName, setNewDeliveryName] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
    const [{ courseOptions }, coursesLoading] = useActivityDetailsFormOptions();

    const cancelModalProps: CancelModalProps = {
        isCancelModalVisible,
        setIsCancelModalVisible,
        onCancelConfirm: () => {
            history.push({
                pathname: `/activities/group/${activityGroup.name}`,
            });
        },
        testPrefix: 'EditActivityGroup',
    };

    useEffect(() => {
        if (activityGroup.child_activities.length === 0) {
            dispatch(
                activityGroupSlice.actions.addDeliveryName(
                    DEFAULT_DELIVERY_NAME,
                ),
            );
            dispatch(addDeliveryOptionWithUser(DEFAULT_DELIVERY_NAME));
        }
    }, [activityGroup.child_activities[0]]);

    const child = activityGroup.child_activities[0];

    const locationOptions = locations.map<SelectProps.Option>((location) => ({
        value: JSON.stringify(location),
        label: getCityString(location),
    }));

    const [saveDisabled, saveDisabledReason] = useMemo(() => {
        for (const validation of Object.keys(updateValidations)) {
            if (!updateValidations[validation].isValid(activityGroup)) {
                return [true, updateValidations[validation].invalidMessage];
            }
        }

        return [false, ''];
    }, [activityGroup]);

    const handleClickSaveButton = async () => {
        setIsSubmitting(true);
        const saveChildActivities = async () => {
            await new Promise((resolve) => setTimeout(resolve, 500));
            await Promise.all(
                activityGroup.child_activities.map(async (activity) => {
                    const filteredActivity = prepareActivityData(activity);

                    if (activity.pk) {
                        return scheduleManagementApi.updateActivityById({
                            id: activity.pk,
                            activity: filteredActivity,
                        });
                    }

                    const {
                        result: { activity_id: createdActivityPk },
                    } = await scheduleManagementApi.createActivity({
                        ...filteredActivity,
                        delivery_sessions:
                            filteredActivity.delivery_sessions.map(
                                ({ id, ...rest }) => rest,
                            ),
                        course_name: courseOptions.find(
                            (course) =>
                                course.catalogId ===
                                activityGroup.catalog_item_id,
                        )?.courseName,
                    });
                }),
            );
        };

        const prepareActivityData = (activity) => {
            const {
                pk,
                delivery_name,
                option_number,
                delivery_sessions,
                ...otherActivityProperties
            } = activity;

            return {
                ...otherActivityProperties,
                delivery_sessions: delivery_sessions.map((session) => {
                    if (session.v_ilt_info) {
                        const { url, recording_url, ...rest } =
                            session.v_ilt_info;
                        return {
                            ...session,
                            v_ilt_info: rest,
                        };
                    }
                    return session;
                }),
            };
        };

        await saveChildActivities();
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setIsSubmitting(false);
        history.push({
            pathname: `/activities/group/${activityGroup.name}/`,
        });
    };

    if (activityGroup.is_loading) {
        return (
            <StatusIndicator type="loading">
                Loading delivery details...
            </StatusIndicator>
        );
    }
    return (
        <Form
            header={<Header variant="h1">{activityGroup.name}</Header>}
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button
                        formAction="none"
                        disabled={isSubmitting || saveDisabled}
                        variant="link"
                        onClick={() => {
                            setIsCancelModalVisible(true);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        formAction="submit"
                        disabled={isSubmitting || saveDisabled}
                        disabledReason={saveDisabledReason}
                        loading={isSubmitting}
                        onClick={() => {
                            handleClickSaveButton();
                        }}
                        data-testid="activity-group-save-button"
                    >
                        {isSubmitting ? 'Saving' : 'Save'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Container header={<Header>Delivery details</Header>}>
                <SpaceBetween direction="vertical" size="l">
                    <FormField label="Activity Type">
                        <Select
                            options={valuesToOptions(activityTypes)}
                            selectedOption={
                                child
                                    ? getSelectedOptionValue(
                                          child.activity_type,
                                      )
                                    : null
                            }
                            placeholder="Select Activity Type"
                            onChange={(e) =>
                                dispatch(
                                    activityGroupSlice.actions.setActivityType(
                                        e.detail.selectedOption.value,
                                    ),
                                )
                            }
                            data-testid="activity-type-select"
                        ></Select>
                    </FormField>

                    <FormField label="Audience">
                        <Select
                            options={valuesToOptions(activityAudiences)}
                            selectedOption={
                                child
                                    ? getSelectedOptionValue(
                                          child.activity_audience,
                                      )
                                    : null
                            }
                            placeholder="Select Audience"
                            onChange={(e) =>
                                dispatch(
                                    activityGroupSlice.actions.setActivityAudience(
                                        e.detail.selectedOption.value,
                                    ),
                                )
                            }
                            data-testid="activity-audience-select"
                        ></Select>
                    </FormField>

                    <FormField label="Delivery Modality">
                        <Select
                            options={valuesToOptions(activityModalities)}
                            selectedOption={
                                child
                                    ? getSelectedOptionValue(
                                          child.activity_modality,
                                      )
                                    : null
                            }
                            placeholder="Select a Delivery Modality"
                            onChange={(e) =>
                                dispatch(
                                    activityGroupSlice.actions.setActivityModality(
                                        e.detail.selectedOption.value,
                                    ),
                                )
                            }
                            data-testid="delivery-modality-select"
                        ></Select>
                    </FormField>

                    <FormField label="Delivery Location">
                        <Select
                            options={locationOptions}
                            selectedOption={
                                child
                                    ? getSelectedOptionValue(
                                          getCityString({
                                              state: child.delivery_state,
                                              city: child.delivery_city,
                                              state_province:
                                                  child.delivery_state,
                                              country: child.delivery_country,
                                          }),
                                      )
                                    : null
                            }
                            placeholder="Select a Delivery Location"
                            onChange={(e) => {
                                const option = JSON.parse(
                                    e.detail.selectedOption.value,
                                ) as LocationItemData;
                                dispatch(
                                    activityGroupSlice.actions.setActivityLocation(
                                        {
                                            timezone: option.city_timezone,
                                            country: option.country,
                                            state: option.state,
                                            city: option.city,
                                            region: option.region,
                                            geo: option.geo,
                                        },
                                    ),
                                );
                            }}
                            filteringType="auto"
                            data-testid="delivery-location-select"
                        ></Select>
                    </FormField>

                    <FormField
                        stretch={true}
                        label="Instructor Pool"
                        constraintText={
                            activityGroup.instructor_pool.length >=
                                MAX_INSTRUCTOR_POOL_SIZE &&
                            AciMessage.MAX_LAG_INSTRUCTOR_POOL_SIZE
                        }
                    >
                        <SpaceBetween direction="horizontal" size="s">
                            <Autosuggest
                                value={instructorSearchText}
                                placeholder="Search for an instructor"
                                loadingText="Searching..."
                                empty="No instructors found"
                                recoveryText="Retry search"
                                finishedText={
                                    instructorOptions.length
                                        ? `Results for: ${instructorSearchText}`
                                        : 'No results'
                                }
                                statusType={
                                    isSearchingInstructors
                                        ? 'loading'
                                        : 'finished'
                                }
                                filteringType="manual"
                                disabled={
                                    activityGroup.instructor_pool.length >=
                                    MAX_INSTRUCTOR_POOL_SIZE
                                }
                                onChange={(e) => {
                                    const clickedOption =
                                        instructorOptions.find(
                                            (instructor) =>
                                                instructor.value ===
                                                e.detail.value,
                                        );

                                    if (clickedOption) {
                                        const instructor = JSON.parse(
                                            clickedOption.value,
                                        ) as InstructorProfileData;
                                        dispatch(
                                            activityGroupSlice.actions.addInstructorToPool(
                                                instructor,
                                            ),
                                        );
                                        setInstructorSearchText('');
                                        setInstructorOptions([]);
                                    } else {
                                        setInstructorSearchText(e.detail.value);
                                    }
                                }}
                                onLoadItems={async (e) => {
                                    if (e.detail.filteringText) {
                                        setIsSearchingInstructors(true);
                                        setInstructorOptions([]);

                                        const { result } =
                                            await instructorManagementApi.getInstructors(
                                                {
                                                    search_text:
                                                        e.detail.filteringText,
                                                    from: 0,
                                                    size: MAX_INSTRUCTOR_SEARCH_RESULTS,
                                                },
                                            );

                                        setInstructorOptions(
                                            result.instructors.map(
                                                (instructor) => ({
                                                    value: JSON.stringify(
                                                        instructor,
                                                    ),
                                                    label: `${instructor.full_name} (${instructor.email})`,
                                                }),
                                            ),
                                        );

                                        setIsSearchingInstructors(false);
                                    }
                                }}
                                options={instructorOptions}
                                data-testid="instructor-search"
                            ></Autosuggest>

                            <TokenGroup
                                disableOuterPadding
                                items={activityGroup.instructor_pool.map(
                                    (instructor) => ({
                                        value: JSON.stringify(instructor),
                                        label: `${instructor.name} (${instructor.email})`,
                                    }),
                                )}
                                onDismiss={({ detail: { itemIndex } }) => {
                                    const instructor =
                                        activityGroup.instructor_pool[
                                            itemIndex
                                        ];
                                    dispatch(
                                        activityGroupSlice.actions.removeInstructorFromPool(
                                            instructor,
                                        ),
                                    );
                                }}
                                data-testid="instructor-token-group"
                            ></TokenGroup>
                        </SpaceBetween>
                    </FormField>
                    {Object.keys(activityGroup.delivery_map).map((delivery) => (
                        <ActivityGroupDelivery
                            key={delivery}
                            name={delivery}
                            mode={'EDIT'}
                        ></ActivityGroupDelivery>
                    ))}
                    <FormField label="Delivery Name">
                        <SpaceBetween direction="horizontal" size="s">
                            <Input
                                type="text"
                                value={newDeliveryName}
                                onChange={(event) =>
                                    setNewDeliveryName(event.detail.value)
                                }
                            ></Input>

                            <Button
                                onClick={() => {
                                    dispatch(
                                        activityGroupSlice.actions.addDeliveryName(
                                            newDeliveryName,
                                        ),
                                    );
                                    setNewDeliveryName('');
                                }}
                                disabled={!newDeliveryName}
                            >
                                Add Delivery
                            </Button>
                        </SpaceBetween>
                    </FormField>
                </SpaceBetween>
            </Container>
            <CancelModal {...cancelModalProps} />
        </Form>
    );
};

export default ActivityGroupEditDeliveryForm;
